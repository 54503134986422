<template>
    <div class="card bs-5 mb-5">
        <div class="fl-te-c pb-3">
            <h4 v-html="'TRAINING ENQUIRY'"></h4>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table"/>
    </div>
</template>

<script>
import urls from '../../../data/urls';

export default {
    name : 'TrainingEnquiry',
    data () {
        return {
            listUrl : urls.admin.training.enquiryList,
            fields  : [
                {
                    name      : 'choose_course',
                    sortField : 'choose_course',
                    title     : 'Course'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Name'
                },
                {
                    name      : 'contact_mail',
                    sortField : 'contact_mail',
                    title     : 'Contact E-Mail'
                },
                {
                    name      : 'contact_no',
                    sortField : 'contact_no',
                    title     : 'Contact Number'
                },
                {
                    name      : 'city',
                    sortField : 'city',
                    title     : 'City'
                },
                {
                    name      : 'create_date',
                    sortField : 'create_date',
                    title     : 'Enquiry Date'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
